
:root {
	--dropdown-bg: #444343;
	--dropdown-text: white;
	--dropdown-fg: #707070;
	--navbar-height: 6em;
}

a {
	text-decoration-line: none;
	color: #337ab7;
}

.capture-input {
	background-color: var(--bg-c6);
	border: none;
}

.capture-hr {
	border-top: 1px solid var(--border-1);
}

.kpi-capture-container {
	display: flex;
	width: 42em;
	margin: -7em 0 0 27%;
	position: absolute;
	padding-top: 1em;
	box-shadow: 1px 6px 16px 14px var(--bg-c6);
	border-radius: 23px;
	font-size: 14.5px;
}

/* Grape alerts */
.ps-success-class div.ps-alert-modal-header {
	background-color: #87a747;
}

.ps-warning-class div.ps-alert-modal-header {
	background-color: #eba61d;
}

.ps-danger-class div.ps-alert-modal-header {
	background-color: #911602;
}

.ps-error-class div.ps-alert-modal-header {
	background-color: #911602;
}

.ps-info-class div.ps-alert-modal-header {
	background-color: #28487a;
}

.ps-table {
	border-left: 4px solid #f1ad14;
}

nav div input {
	display: none;
}

.ps-navbar .ps-nav-links li a {
	transition: background-color 0.3s ease;
}

.ps-navbar .ps-nav-links li a:hover {
	transition: background-color 0.3s ease;
}

.ps-nav-links li:hover .ps-nav-dropdown {
	top: 4.2em;
}

.ps-navbar .ps-nav-links li a:active {
	transition: background-color 0.3s ease;
}

.ps-navbar .fa-angle-down {
	color: #f1ad14
}

.ps-navbar .ps-nav-dropdown {
	background-color: var(--fg-c6);
	border-radius: 5px;
	top: 4.2em;
}

.ps-navbar .ps-nav-dropdown li a {
	color: var(--text-c4);
}


.ps-navbar .ps-nav-dropdown li:first-child {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.ps-navbar .ps-nav-dropdown li:last-child {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

@media screen and (max-width: 970px) {
	.ps-link-container .ps-menu-btn {
		display: block;
		position: absolute;
		right: 0;
  
	}
}

/* ps side bar */
.ps-sidebar {
	background-color: var(--fg-c4);
	color: var(--text-c4);
	z-index: 1;
	box-shadow: 0px 1px 15px 2px var(--bg-shadow);
	border-right: none;
}

.ps-sidebar-content {
	padding: 1em 2em 0em 2em;
}

.ps-sidebar-container {
	border: none;
}

.ps-sidebar ul li {
	background-color: var(--fg-c4);
	color: var(--text-c4);
	padding: 1em;
	list-style: none;
	transition: background-color 0.3s ease, border-left 0.3s ease;
}

.ps-sidebar ul li:hover {
	color: var(--text-c4);
	background: none;
	transition: color 0.3s ease, background-color 0.3s ease;
}

.ps-sidebar ul li.active {
	color: var(--text-c4);
	padding: 1em;
	background-color: var(--fg-c6);
	transition: background-color 0.3s ease, border-left 0.3s ease;
}

.ps-sidebar-left ul li.active {
	border-left: 8px solid #f1ad14;
	transition: background-color 0.3s ease, border-left 0.3s ease;
}

.ps-bordered-block {
	background-color: var(--bg-c1);
}

/* ------ */
.cat-page-header {
	color: #f1ad14;
}

h1 {
	font-size: 23px;
}

h3 {
	font-size: 20px;
}

.ps-block {
	height: auto;
	border: none;
	padding: 0em 2em 0 0;
	box-shadow: 1px 6px 16px 3px var(--bg-c6);
	border-radius: 23px;
	min-height: 5em;
	font-size: 14.5px;
	min-width: 15em;
	max-width: 19em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: var(--bg-c1);
}

.ps-block::-webkit-scrollbar {
	display: none;
}

.ps-block .ps-header {
	font-size: 15px;
}

.ps-header {
	font-size: 16px;
	color: #f1ad14;
}

.ps-table-single-row th {
	width: 25%;
}

.ps-table tbody tr > td.parent-kpi_id a div {
	padding-left: 25px;
}

.ps-table-sigle-row-container {
	border-left: 3px solid #f1ad14;
	margin-left: 1em;
}

table.ps-table-single-row,
table.ps-table-single-row tbody td {
	padding: 0 3em 0 0;
	border: none;
}

/* ps-default table */
.ps-table thead th,
.ps-table tbody,
.ps-table thead td,
.ps-table tbody td {
	background-color: var(--bg-c2);
	border: 1px solid var(--bg-c4);
}

.ps-table-two-headers thead tr:first-of-type th:first-of-type {
	border: none;
	background-color: var(--bg-c1);
	color: #f1ad14;
	font-weight: 300;
	font-size: 20px;
}

/* Buttons */
.ps-btn-primary {
	border: none;
	background-color: var(--bg-c2);
	color: var(--text-c1);
}

.ps-btn-danger {
	background-color: var(--fg-default);
	color: var(--fg-c4);
	border-radius: 12px;
}

.btn-edit,
.btn-delete,
.btn-view {
	font-size: 19px;
	padding: 0 6px 0 6px;
}

.btn-edit {
	color: #f1ad14;
}

.ps-btn-success {
	background-color: #f1ad14;
}

.ps-btn {
	color: var(--text-c5);
}

.ps-btn-default {
	color: var(--text-c5);
}

.table-two-header-btn {
	display: flex;
	justify-content: flex-end;
	top: 2.8em;
	position: relative;
}

.btn-add,
.btn-remove {
	border-radius: 50%;
	height: 2em;
	width: 2em;
	border: none;
	color: white;
}

.btn-add {
	background-color: #87a747;
}

.btn-remove {
	background-color: #911602;
}

/* Progress Bar Styling */
.ps-table .progress {
	height: 20px;
	background-color: var(--bg-c2);
	border: 1px solid var(--bg-c4);
	border-radius: 4px;
	position: relative;
	width: 100%;
	overflow: hidden;
}

.ps-table .progress .progress-bar {
	background-color: #f1ad14;
	width: 0;
	height: 100%;
	border-radius: 4px;
	position: relative;
	transition: width 2s linear;
	overflow: hidden;
}

.ps-table .progress .progress-bar span {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	color: var(--fg-default);
	z-index: 2;
}

.ps-table .progress .progress-bar::before {
	content: '';
	background-image: linear-gradient(45deg, rgba(255, 140, 0, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 140, 0, 0.4) 50%, rgba(255, 140, 0, 0.4) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem;
	position: absolute;
	top: 0;
	left: -100%;
	width: 200%;
	height: 100%;
	animation: moveStripes 50s linear infinite;
}

@keyframes moveStripes {
	0% {
		background-position: -100% 0;
	}
	100% {
		background-position: 100% 0;
	}
}

.content {
	max-height: 50vh;
	overflow: scroll;
}

.table>tbody>tr>td {
	border-top: 1px solid var(--bg-c2);
}

.ps-tabs > li > a {
	min-width: 6em;
	text-align: center;
}

.ps-tabs > li.active > a,
.ps-tabs > li.active > a:focus,
.ps-tabs > li.active > a:hover {
	background-color: #f1ad14 !important;
	border: none;
	color: white;
}

.ps-tabs li a:hover {
	color: var(--text-c1);
}

input[type=range] {
	-webkit-appearance: none;
	margin-top: 1.5em;
	width: 100%;
	background: transparent;
}

input[type=range]:focus {
	outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 1em;
	cursor: pointer;
	background: var(--text-c1);
	border-radius: 8px;
}

input[type=range]::-webkit-slider-thumb {
	height: 1.3em;
	width: 1.25em;
	border-radius: 16px;
	background: #f1ad14;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -1px;
}

input[type=range]::-moz-range-track {
	width: 100%;
	height: 30px;
	cursor: pointer;
	background: var(--text-c1);
	border-radius: 8px;
}

input[type=range]::-moz-range-thumb {
	height: 40px;
	width: 20px;
	border-radius: 16px;
	background: #f1ad14;
	cursor: pointer;
}

input[type=range]::-ms-track {
	width: 100%;
	height: 30px;
	cursor: pointer;
	background: transparent;
	border-color: transparent;
	color: transparent;
}

input[type=range]::-ms-thumb {
	margin-top: 1px;
	height: 40px;
	width: 20px;
	border-radius: 16px;
	background: #f1ad14;
	cursor: pointer;
}

.ps-input option {
	padding: 3px 0 3px 0;
}

/* required field */
.required-symbol {
	color: #f1ad14;
	margin-left: 2px;
	font-size: 16px;
}

.required-field-label {
	font-weight: bold;
	padding-left: 1em;
	color: #f1ad14;
	font-style: italic;
	background-color: var(--bg-c1);
}

/* pagination */
.ps-pagination > .active > a,
.ps-pagination > .active > span,
.ps-pagination > .active > a:hover,
.ps-pagination > .active > span:hover,
.ps-pagination > .active > a:focus,
.ps-pagination > .active > span:focus {
	background-color: var(--bg-c5);
	border: none;
}

select option:hover {
	opacity: .5;
}

/* list*/
/*bullets*/
.arrow-bullet li {
	list-style-type: disclosure-closed;
}

.arrow-bullet li::marker {
	color: #f0ad4e;
}

select.ps-input.dropdown option {
	background-color: var(--bg-c5);
	color: white;
	border-radius: 4px;
	padding: 5px;
}

.sidebar {
	width: 25%;
	float: left;
}

.targets-info-container {
	display: flex;
}

.detail {
	margin-bottom: 10px;
}

.header {
	font-weight: bold;
}

.value {
	font-weight: bold;
}

.close-dialog {
	border-radius: 50%;
	border: none;
	right: 1em;
	position: absolute;
	top: 1em;
}

.error-message {
	color: red;
	font-style: italic;
}

.selected-page {
	font-weight: bold;
	border-left: none !important;
	border-bottom: 1px solid var(--bg-c2);
	text-align: center;
	margin: 0 10px 12px 10px !important;
	text-transform: uppercase;
}

/* SDG */
.goal-icons {
	height: 14em;
}

.goal-item {
	padding: 5px;
}

.goal-item:hover {
	padding: 3px;
}

.search-block {
	justify-content: center;
	display: flex;
	align-items: center;
	background-color: var(--fg-c4);
	color: var(--text-c4);
	margin: 5px;
	cursor: pointer;
}

.search-block:hover {
	background-color: var(--bg-shadow);
	margin: 3px;
}

.sdg-layout {
	display: grid;
	grid-template-columns: repeat(6, 0fr);
	background-color: var(--bg-c6);
	border-radius: 1em;
	width: 90em;
	padding: 1em;
}

.total-linked-block {
	position: absolute;
	z-index: 1;
	margin: -1.5em 0 57px 5.5em;
	background-color: #ffffffbf;
	width: 3em;
	border-radius: 8px;
	text-align: center;
	box-shadow: 1px -1.5px 6px 1px #201f1fa6;
}

.sdg-popup {
	visibility: hidden;
	opacity: 0;
	z-index: 10;
	width: 27em;
	position: absolute;
	display: inline-table;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.597);
	overflow-y: scroll;
	border-radius: 24px;
	background-color: var(--bg-c1);
	margin-left: -5em;
	margin-top: -10em;
}

.sdg-popup-header {
	border-radius: 22px;
	background-color: var(--fg-c4) ;
	color: var(--text-c4) ;
	padding: 16px;
	font-size: 20px;
	text-transform: uppercase;
	text-align: center;
}

.sdg-popup.visible {
	visibility: visible;
	opacity: 1;
	transition: visibility 0s linear 0.2s, opacity 0.5s linear;
}

.sdg-details-img {
	max-height: 17.5em;
	width: auto;
	max-width: 100%;
}

.target-row {
	padding: 1em;
}

.target-info-row:hover {
	background-color: var(--bg-c2);
	cursor: pointer;
}

.target-row-img {
	height: 3.5em;
}

.sdg-font {
	font-family: 'Oswald', system-ui;
}

@media (max-width: 768px) {
	.sdg-details-img {
		height: 12em;
	}
}

@media (max-width: 480px) {
	.sdg-details-img {
		height: 8em;
	}
}

.ps-alert-nodata {
	font-style: italic;
}

.dropdown-icon:after {
	color: var(--text-c1);
	display: contents;
	font-size: 7px;
	margin-left: 12px;
}

.sdg-target-indicators-list {
	height: 31em;
	max-height: 31em;
	overflow-y: scroll;
	background-color: var(--bg-c1);
}

.indicator-dropdown div:hover {
	background-color: var(--border-2);
	cursor: pointer;
}

.indicator-dropdown-btn {
	border-bottom: 1px solid var(--bg-c6);
	border-top: 1px solid var(--bg-c6);
	padding: 10px 0 10px 4em;
	margin-top: 1em;
	cursor: pointer;
}

.sdg-target-indicators-list::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 10px;
}

.sdg-target-indicators-list::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, .5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.sdg-target-indicators-list::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

.selected-target-indicator {
	background-color: var(--bg-c2);
}

.sdg-target-indicator-table-header {
	text-align: center;
	background-color: var(--fg-c4);
	line-height: 2em;
	color: white;
	margin-top: 0;
}

.project-link:not(:last-child) .sdg-project-list {
	border-bottom: 1px solid var(--bg-c4);
}

.sdg-project-list {
	padding: 3px 2px 3px 0;
}

.role-item span:not(:last-child)::after,
.role-item div:not(:last-child)::after {
	content: "|";
	color: #f1ad14;
	margin: 0 5px;
}

.user-row {
	border-right: none !important;
	border-left: none !important;
	text-align: center !important;
}

.circle-icon-container {
	background-color: white;
	border-radius: 50%;
	overflow: hidden;
}

.circle-icon {
	width: 97%;
	padding: 8px 0 0 10px;
}

.target-ind-desc {
	border-bottom: 1px solid var(--bg-c4);
	margin-bottom: 9px;
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	transition: max-height 0.5s ease, opacity 0.5s ease;
}

.target-ind-desc.visible {
	max-height: 7em;
	opacity: 1;
}

.sdg-search-blocks {
	width: 33.3%;
}

.search-selected-target-indicator {
	background-color: var(--bg-c2);
}

.search-selected-item {
	background-color: var(--border-2);
	box-shadow: 3px 3px 13px -3px var(--bg-shadow);
}

.sdg-search-bu-link:not(:last-child) .sdg-project-list {
	border-bottom: 1px solid var(--bg-c4);
}

.search-page-three-containers {
	margin-top: 1em;
	padding: 1em 0 1em 0;
	border-bottom: 1px solid var(--bg-c2);
	display: flex;
	margin-bottom: 1em;
}

/* sdg search table */
.sdg-search-info-tables thead td,
.sdg-search-info-tables tbody td {
	background-color: var(--bg-c1);
	border-left: none;
	border-right: none;
	border-top: 1px solid var(--bg-c2);
}

.sdg-search-info-tables tbody {
	border-bottom: 2px solid var(--bg-shadow);
	border-right: none;
}

.sdg-search-info-tables thead th {
	border: none; 
}

.sdg-search-info {
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	transition: max-height 0.5s ease, opacity 0.5s ease;
}

.sdg-search-info.visible {
	max-height: 7em;
	opacity: 1;
}

.sdg-search-headings {
	justify-content: space-between;
	width: 72%;
	margin: 1em 0 -14px 14%;
	display: flex;
	font-size: 20px;
}

.sdg-search-headings-container {
	background-color: var(--fg-c6);
	color: var(--text-c4);
	box-shadow: 3px 6px 8px -3px var(--bg-shadow);
}

/* User setup tables */
.clickable-row {
	cursor: pointer;
}

.click-view-row:hover {
	background-color: #f1ad14 !important;
}

.ck {
	color: var(--text-c5) !important;
}

.ps-dialog-header,
.ps-dialog-footer {
	padding: 0.7em !important;
}

.ps-dialog-footer {
	display: flex;
	justify-content: space-between;
}

/* kpi data checks - outliers & missing values */
.kpi-outlier-flag {
	background-color: #911602;
	color: #fff;
	padding: 4px;
	border-radius: 0.6em;
}

/* kpi data checks - warning */
.kpi-warning-flag {
	background-color: #ffb726;
	color: #fff;
	padding: 4px;
	border-radius: 0.6em;
}

.kpi-value-details-table > tbody > tr > td {
	padding: 0.5em;
}
