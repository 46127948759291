/* Regular */
@font-face {
	font-family: 'Roboto';
	src: url('Roboto/Roboto-Regular.ttf');
	font-weight: normal;
	font-style: normal;
}

/* Bold */
@font-face {
	font-family: 'Roboto';
	src: url('Roboto/Roboto-Bold.ttf');
	font-weight: bold;
	font-style: normal;
}

/* Bold italic */
@font-face {
	font-family: 'Roboto';
	src: url('Roboto/Roboto-BoldItalic.ttf');
	font-weight: bold;
	font-style: italic;
}

/* Italic */
@font-face {
	font-family: 'Roboto';
	src: url('Roboto/Roboto-Italic.ttf');
	font-weight: normal;
	font-style: italic;
}

body {
	font-family: 'Roboto', sans-serif; /* Use 'sans-serif' as a fallback font */
	background-color: var(--bg-c1);
	color: var(--fg-c1);
}