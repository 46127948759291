/**
* Catalytix variable settings for colours in the system
*/
:root.theme-catalytix-dark {
	--fg-c1: #cfcfcf;
	--bg-c1: #444343;
	--text-c1: #f0f0f0;

	--fg-c2: purple;
	--bg-c2: #4d4d4d;
	--text-c2: purple;

	--fg-c3: blue;
	--bg-c3: #575658;
	--text-c3: #f0f0f0;

	--fg-c4: #575658;
	--bg-c4: #202020;
	--text-c4: #f0f0f0;

	--fg-c5: #f1ad14 !important;
	--bg-c5: #444343;
	--text-c5: #444343;

	--fg-c6: #444343;
	--bg-c6: #1a1a1a;

	--border-1: darkgrey;
	--border-2: #f3f3f3;
	--border-3: #dee2e6;
	--border-4: #b2b3b4;

	--bg-shadow: #f0f0f0;

	--fg-warning: #f0ad4e;
	--fg-danger: #c9302c;
	--fg-success: #5cb85c;
	--fg-default: #fff;
	--fg-primary: #3498db;

	--fa-primary-color: #C3C8D3;
	--fa-secondary-color: #cacaca;

	--font-family: "Lato", sans-serif; 
}
